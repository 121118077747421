/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import {useCallApi} from '../../../../app/modules/utils'
import { useAuth } from '../../../../app/modules/auth'
import { Loading } from '../../../../app/components/Loading'

type Props = {
  className: string
}

const TablesWidget5: React.FC<Props> = ({className}) => {
  const [monthSalesCategories, setMonthSalesCategories] = useState<any>(null)
  const callApi = useCallApi()
   const {date,tab}=useAuth();
   const [loading, setLoading] = useState(true)

  const getSAllesCategories = async () => {
    try {
setLoading(true)
      const {data} = await callApi({
        route: tab=="day" || tab=="dateP" || tab=="yesterday" ?'api/day-sales-by-categorie?date='+date:tab=="week"?'api/week-sales-by-categorie':tab=="month"?'api/month-sales-by-categorie':"api/year-sales-by-categorie",
        method: 'GET',
      })
      
      setMonthSalesCategories(data.vente_by_category)
      setLoading(false)

      // console.log(data.data.today_sales_total_amount)
    } catch (error) {
      setLoading(false)
      console.error('Error fetching product data:', error)
    }
  }

 


  useEffect(() => {
    getSAllesCategories()
  }, [tab,date])

  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Vente par Catégorie</span>
        </h3>
        <div className='card-toolbar'>
          {/* <ul className='nav'>
          <li className='nav-item'>
              <a
                onClick={getSAllesDayCategories}
                className='nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary active fw-bold px-4'
                data-bs-toggle='tab'
                href='#kt_table_widget_5_tab_1'
              >
                Jour
              </a>
            </li>
            
            <li className='nav-item'>
              <a
               onClick={getSAllesYearCategories}
                className='nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary fw-bold px-4 me-1'
                data-bs-toggle='tab'
                href='#kt_table_widget_5_tab_2'
              >
                Semaine
              </a>
            </li>
            <li className='nav-item'>
              <a
                onClick={getSAllesCategories}
                className='nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary  fw-bold px-4 me-1'
                data-bs-toggle='tab'
                href='#kt_table_widget_5_tab_3'
              >
                Mois
              </a>
            </li>
         
          </ul> */}
        </div>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      {monthSalesCategories &&
        monthSalesCategories.map((categorie: any, index: number) => (
          <div key={index} className="modal fade" id={'modalproductCategorie' + index} >
            <div className="modal-dialog modal-dialog-centered ">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title text-info fs-4">Produit de la catégorie :  <span className='text-gray-400'>{categorie.name} </span></h5>
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body " style={{    height:" 22rem",
    overflowX: "auto"}}>
                  {/* Add your modal content here */}
                  {/* For example, you can display the list of products within this category */}
                  {categorie.products &&
                    categorie.products.map((product: any, productIndex: number) => (
                      <div key={productIndex}>
                        <p><span className='fw-bold text-info '>{product.quantity} * </span> <span className='fw-bold'>{product.product_name}</span></p>
                        {/* Add other product details as needed */}
                      </div>
                    ))}
                </div>
                <div className="modal-footer">
                  <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Annuler</button>
                </div>
              </div>
            </div>
          </div>
        ))}
      <div className='card-body py-3' style={{height:"30rem",overflowX: "auto"}}>
        <div className='tab-content'>
          {/* begin::Tap pane */}
          <div className='' id='kt_table_widget_5_tab_1'>
            {/* begin::Table container */}
            <div className='table-responsive'>
              {/* begin::Table */}
              <table className='table table-row-dashed table-row-gray-200 align-middle gs-0 gy-4'>
                {/* begin::Table head */}
                <thead>
                  <tr className='border-0'>
                    <th className='p-0 w-150px'></th>
                    {/* <th className='p-0 min-w-50px'></th>
                    <th className='p-0 min-w-140px'></th> */}
                    <th className='p-0 w-50px'></th>
                  </tr>
                </thead>
                {/* end::Table head */}
                {/* begin::Table body */}
                <tbody>
                  {loading ? <Loading/>: monthSalesCategories &&
                    monthSalesCategories.map((categorie: any, index: number) => {
                      return (
                        <tr  key={index}
                        className="border-top"
                        data-bs-toggle="modal"
                        data-bs-target={'#modalproductCategorie' + index}>
                          <td>{categorie.name}</td>
                          {/* <td></td>

                          <td className='text-end text-muted fw-semibold'></td>*/}
                          <td className='text-end'>
                            <span className='badge badge-light-success'>{categorie.total}  MAD</span>
                          </td>
                        </tr>
                      )
                    })}
                </tbody>
                {/* end::Table body */}
              </table>
            </div>
            {/* end::Table */}
          </div>
          {/* end::Tap pane */}
          {/* begin::Tap pane */}
        
          {/* end::Tap pane */}
        </div>
      </div>
      {/* end::Body */}
    </div>
  )
}

export {TablesWidget5}
