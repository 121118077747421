import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'
import {
  MixedWidget11,
  ListsWidget3,
  TablesWidget5,
  StatisticsWidget5,
  ListsWidget1,
  ListsWidget6,
  TablesWidget13,
  TablesWidget12,
  TablesWidget11,
  MixedWidget4,
} from '../../../_metronic/partials/widgets'
import {useEffect, useState} from 'react'
import {useCallApi} from '../../modules/utils'
import {Link} from 'react-router-dom'
import {ModalProductVendus} from '../../components/ModalProductVendus'
import {ListsWidget10} from '../../../_metronic/partials/widgets/lists/ListsWidget10'
import {ChartByHour} from '../../components/dashboard/ChartByHour'
import {ChartByMounth} from '../../components/dashboard/ChartByMounth'
import {ChartByYear} from '../../components/dashboard/ChartByYear'
import {useAuth} from '../../modules/auth'
import {ChartByWeek} from '../../components/dashboard/ChartByWeek'
import "../dashboard/style.css";
import { BranSales } from '../../../_metronic/partials/widgets/tables/BranSales'
const DashboardPage: React.FC = () => {
  const [todaySalles, setTodaySalles] = useState<any>(null)
  const callApi = useCallApi()
  const currentDate = new Date()
  const year = currentDate.getFullYear()
  const month = String(currentDate.getMonth() + 1).padStart(2, '0')
  const day = String(currentDate.getDate()).padStart(2, '0')
  const dated = `${year}-${month}-${day}`
  const {date, setDate, setTab, tab} = useAuth()
  const [isNavFixed, setNavFixed] = useState(false);

  const [benifices, setBenifices] = useState<any>(null)
    const [turnovertotal, setturnovertotal] = useState<any>(0)

  const calculateYesterday = () => {
    const currentDate = new Date()
    currentDate.setDate(currentDate.getDate() - 1)
    const year = currentDate.getFullYear()
    const month = String(currentDate.getMonth() + 1).padStart(2, '0')
    const day = String(currentDate.getDate()).padStart(2, '0')
    return `${year}-${month}-${day}`
  }
  const getSAlles = async (date: string, tab: string) => {
    setTab(tab)
    setTodaySalles(null)
    setBenifices(null)
    setDate(date)
    try {
      const {data} = await callApi({
        route: 'api/today-sales-overall-report?date=' + date,
        method: 'GET',
      })
      const benifices = await callApi({
        route: `api/profit-loss-report?start_date=${date}&end_date=${date}`,
        method: 'GET',
      })
      setBenifices(benifices.data.data)
      setTodaySalles(data.data)
    } catch (error) {
      console.error('Error fetching product data:', error)
    }
  }
  const getTotalTurnover = async () => {
    try {
      const {data} = await callApi({
        route: `api/total-stock-amount-purchase`,
        method: 'GET',
      })

      
   

      setturnovertotal(data.data.totalTurnover)
     
    } catch (error) {
      console.error('Error fetching product data:', error)
    }
  }
 
  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const shouldNavBeFixed = scrollPosition > 55; // Change this value based on your requirements

      setNavFixed(shouldNavBeFixed);
    };

    window.addEventListener('scroll', handleScroll);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  useEffect(() => {
    getSAlles(dated,"day")
    getTotalTurnover()
  }, []);
  return (
    <>
     <div className={`bg-white  rounded-1 ${isNavFixed ? 'fixed-nav' : ''}`} style={{padding: '1%', marginBottom: '1%'}}>
     <ul
      className={`nav `}
      style={{alignItems: 'center', justifyContent: 'space-around' }}
        >
          <li className='nav-item '>
            <a
              onClick={() => getSAlles(dated, 'day')}
              className='nav-link btn btn-sm btn-color-muted btn-active active btn-active-light-primary active fw-bold px-4'
              data-bs-toggle='tab'
              href='#kt_table_widget_5_tab_1'
            >
              Aujourd'hui
            </a>
          </li>

          <li className='nav-item '>
            <a
              onClick={() => getSAlles(calculateYesterday(), 'yesterday')}
              className='nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary fw-bold px-4 me-1'
              data-bs-toggle='tab'
              href='#kt_table_widget_5_tab_2'
            >
              hier
            </a>
          </li>
          <li className='nav-item '>
            <a
              onClick={() => getSAlles('', 'week')}
              className='nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary fw-bold px-4 me-1'
              data-bs-toggle='tab'
              href='#kt_table_widget_5_tab_2'
            >
              Semaine
            </a>
          </li>
          <li className='nav-item '>
            <a
              onClick={() => getSAlles('', 'month')}
              className='nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary fw-bold px-4 me-1'
              data-bs-toggle='tab'
              href='#kt_table_widget_5_tab_2'
            >
              Mois
            </a>
          </li>
          <li className='nav-item '>
            <a
              onClick={() => getSAlles('', 'year')}
              className='nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary fw-bold px-4 me-1'
              data-bs-toggle='tab'
              href='#kt_table_widget_5_tab_2'
            >
              Année
            </a>
          </li>
          <li className='nav-item '>
            <div className='me-3'>
              <input
                className='form-control  form-control-solid nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary fw-bold px-4 me-1'
                data-bs-toggle='tab'
                type='date'
               
                onChange={(e) => {
                  getSAlles(e.target.value, 'dateP')
                }}
                // onBlur={handleBlur}
                // value={values.cp_date_debut}
              />
            </div>
          </li>
        </ul>
     </div>
   
      <div className=' bg-white rounded' style={{padding: '1%', marginBottom: '1%'}}>
       
        <div className='row  g-5 g-xl-10'>
          <div className='col-xl-3 '>
            <StatisticsWidget5
              href={'#'}
              chiffre={true}
              className='card-xl-stretch mb-xl-8'
              svgIcon='/media/icons/duotune/finance/fin003.svg'
              color='primary'
              iconColor='white'
              title="CHIFFRE D'AFFAIRES"
              myDate={date}
              cash={
                todaySalles &&
                (tab == 'day' || tab == 'yesterday'
                  ? todaySalles?.today_sales_cash_payment
                  : tab == 'week'
                  ? todaySalles?.week_sales_cash_payment
                  : tab == 'month'
                  ? todaySalles?.month_sales_cash_payment
                  : tab == 'year'
                  ? todaySalles?.year_sales_cash_payment
                  : todaySalles?.today_sales_cash_payment)
              }
              bank={
                todaySalles &&
                (tab == 'day' || tab == 'yesterday'
                  ? todaySalles?.today_sales_bank_transfer_payment
                  : tab == 'week'
                  ? todaySalles?.week_sales_bank_transfer_payment
                  : tab == 'month'
                  ? todaySalles?.month_sales_bank_transfer_payment
                  : tab == 'year'
                  ? todaySalles?.year_sales_bank_transfer_payment
                  : todaySalles?.today_sales_bank_transfer_payment)
              }
              description={
                todaySalles &&
                (tab == 'day' || tab == 'yesterday'
                  ? todaySalles?.today_sales_total_amount
                  : tab == 'week'
                  ? todaySalles?.total_week_amount
                  : tab == 'month'
                  ? todaySalles?.total_month_amount
                  : tab == 'year'
                  ? todaySalles?.total_year_amount
                  : todaySalles?.today_sales_total_amount)
              }
              titleColor='white'
              descriptionColor='white'
            />
          </div>

          <div className='col-xl-3'>
            <StatisticsWidget5
              myDate={date}
              className='card-xl-stretch mb-xl-8'
              svgIcon='/media/icons/duotune/ecommerce/ecm002.svg'
              color='success'
              iconColor='white'
              title='COMMANDES'
              href='/commande'
              description={
                todaySalles &&
                (tab == 'day' || tab == 'yesterday'
                  ? todaySalles?.today_total_sales
                  : tab == 'week'
                  ? todaySalles.total_sales_week
                  : tab == 'month'
                  ? todaySalles.total_sales_month
                  : tab == 'year'
                  ? todaySalles.total_sales_year
                  : todaySalles.today_total_sales)
              }
              titleColor='white'
              descriptionColor='white'
            />
          </div>

          <div className='col-xl-3' data-bs-toggle='modal' data-bs-target='#modalproductvendus'>
            <StatisticsWidget5
              href={`#`}
              myDate={date}
              className='card-xl-stretch mb-5 mb-xl-8'
              svgIcon='/media/icons/duotune/files/fil025.svg'
              color='dark'
              iconColor='gray-100'
              title='PRODUIT VENDUS'
              description={
                todaySalles &&
                (tab == 'day' || tab == 'yesterday'
                  ? todaySalles?.today_sales_total_products_sold
                  : tab == 'week'
                  ? todaySalles.total_products_sold_current_week
                  : tab == 'month'
                  ? todaySalles.total_products_sold_current_month
                  : tab == 'year'
                  ? todaySalles.total_products_sold_current_year
                  : todaySalles.today_total_sales)
              }
              titleColor='gray-100'
              descriptionColor='gray-100'
            />
          </div>

          {/* <ModalProductVendus data={
                todaySalles &&
                (tab == 'day' || tab == 'yesterday'
                  ? todaySalles?.today_sales_total_products_sold
                  : tab == 'week'
                  ? todaySalles.total_products_sold_current_week
                  : tab == 'month'
                  ? todaySalles.total_products_sold_current_month
                  : tab == 'year'
                  ? todaySalles.total_products_sold_current_year
                  : todaySalles.today_total_sales)
              } /> */}
          <div className='col-xl-3'>
            <StatisticsWidget5
              myDate={date}
              href={'#'}
              className='card-xl-stretch mb-5 mb-xl-8'
              svgIcon='/media/icons/duotune/general/gen050.svg'
              color='warning'
              iconColor='white'
              title='TOTAL DES ANNULÉES'
              description={
                
                todaySalles &&
                (tab == 'day' || tab == 'yesterday'
                  ? todaySalles?.today_sales_cancelled
                  : tab == 'week'
                  ? todaySalles.total_sales_cancelled_week
                  : tab == 'month'
                  ? todaySalles.total_sales_cancelled_month
                  : tab == 'year'
                  ? todaySalles.total_sales_cancelled_year
                  : todaySalles.today_sales_cancelled)
              } 
              
              titleColor='white'
              descriptionColor='white'
            />
          </div>
          <div className='col-xl-3'>
            <StatisticsWidget5
              myDate={date}
              href={'#'}
              className='card-xl-stretch mb-5 mb-xl-8'
              svgIcon='/media/icons/duotune/ecommerce/bitcoine.svg'
              color='danger'
              iconColor='white'
              title='Bénifices '
              description={
                
                benifices &&
                (tab == 'day' || tab == 'yesterday'
                  ? benifices?.Revenue
                  : tab == 'week'
                  ? benifices?.Revenue_week
                  : tab == 'month'
                  ? benifices?.Revenue_month
                  : tab == 'year'
                  ? benifices?.Revenue_year
                  : benifices?.Revenue)
              } 
              titleColor='white'
              descriptionColor='white'
            />
          </div>
          <div className='col-xl-3'>
            <StatisticsWidget5
              myDate={""}
              href={'/turnover'}
              className='card-xl-stretch mb-5 mb-xl-8'
              svgIcon='/media/icons/duotune/finance/fin003.svg'
              color='info'
              iconColor='white'
              title="Chiffre d'affaires Stock "
              description={turnovertotal} 
              titleColor='white'
              descriptionColor='white'
            />
          </div>
         
        </div>
      </div>

      {/* begin::Row */}

      {/* end::Row */}

      {/* begin::Row */}
      <div className='row g-5 g-xl-8'>
        {/* begin::Col */}
        {/* <div className='col-xl-3'>
          <ListsWidget1 className='card-xl-stretch mb-xl-8' />
        </div> */}
        {/* end::Col  catego*/}

        {/* begin::Col */}
        <div className='col-xl-6'>
          <TablesWidget5 className='card-xl-stretch mb-5 mb-xl-8' />
        </div>
        <div className='col-xl-6'>
          <BranSales className='card-xl-stretch mb-5 mb-xl-8' />
        </div>
        
        {/* end::Col */}
      </div>
      <div className={`card mb-3  row`}>
          {/* <div className='card-header justify-content-center border-0 '>
         <div className='card-toolbar '>
            {/* <ul className='nav nav-pills mb-3' id='pills-tab' role='tablist'>
              <li className='nav-item' role='presentation'>
                <button
                  className='nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary active fw-bold px-4'
                  id='pills-home-tab'
                  data-bs-toggle='pill'
                  data-bs-target='#pills-home'
                  type='button'
                  role='tab'
                  aria-controls='pills-home'
                  aria-selected='true'
                >
                  Aujourd'hui
                </button>
              </li>
              <li className='nav-item' role='presentation'>
                <button
                  className='nav-link btn btn-sm btn-color-muted btn-active btn-active-light-info  fw-bold px-4'
                  id='pills-profile-tab'
                  data-bs-toggle='pill'
                  data-bs-target='#pills-profile'
                  type='button'
                  role='tab'
                  aria-controls='pills-profile'
                  aria-selected='false'
                >
                  Mois
                </button>
              </li>
              <li className='nav-item' role='presentation'>
                <button
                  className='nav-link btn btn-sm btn-color-muted btn-active btn-active-light-danger  fw-bold px-4'
                  id='pills-contact-tab'
                  data-bs-toggle='pill'
                  data-bs-target='#pills-contact'
                  type='button'
                  role='tab'
                  aria-controls='pills-contact'
                  aria-selected='false'
                >
                  Année
                </button>
              </li>
            </ul> 
          </div> 
        </div>*/}

          {/* begin::Body */}
          <div className='card-body p-0 d-flex justify-content-between flex-column overflow-hidden'>
            <div className='tab-content' id='pills-tabContent'>
              <div
                className={` tab-pane   ${
                  tab == 'day' || tab == 'yesterday' || tab == 'dateP' ? ' show active' : 'fade'
                }`}
                id='pills-home'
                role='tabpanel'
                aria-labelledby='pills-home-tab'
              >
                <ChartByHour
                  date={date}
                  className='card-xxl-stretch-50 mb-5 mb-xl-8'
                  chartColor='primary'
                  chartHeight='400px'
                />
              </div>
              <div
                className={` tab-pane   ${tab == 'month' ? ' show active' : 'fade'}`}
                id='pills-profile'
                role='tabpanel'
                aria-labelledby='pills-profile-tab'
              >
                {' '}
                <ChartByMounth
                  date={date}
                  className='card-xxl-stretch-50 mb-5 mb-xl-8'
                  chartColor='info'
                  chartHeight='400px'
                />
              </div>
              <div
                className={` tab-pane   ${tab == 'week' ? ' show active' : 'fade'}`}
                id='pills-profile'
                role='tabpanel'
                aria-labelledby='pills-profile-tab'
              >
                {' '}
                <ChartByWeek
                  date={date}
                  className='card-xxl-stretch-50 mb-5 mb-xl-8'
                  chartColor='warning'
                  chartHeight='400px'
                />
              </div>
              <div
                className={` tab-pane  show ${tab == 'year' ? ' show active' : 'fade'}`}
                id='pills-contact'
                role='tabpanel'
                aria-labelledby='pills-contact-tab'
              >
                <ChartByYear
                  date={date}
                  className='card-xxl-stretch-50 mb-5 mb-xl-8'
                  chartColor='danger'
                  chartHeight='400px'
                />
              </div>
            </div>
          </div>
        </div>
      {/* end::Row */}

      {/* begin::Row */}

      {/* end::Row */}

      {/* begin::Row */}
      <div className='row g-5 g-xl-8'>
        {/* begin::by caissier */}

        <div className='col-xl-6'>
          <ListsWidget3 className=' card-xxl-stretch mb-xl-3' />
        </div>
        {/* end::Col */}

        {/* begin::Col commecrial*/}
        <div className='col-xl-6'>
          <ListsWidget10 className='card-xl-stretch mb-5 mb-xl-8' />
        </div>
        {/* end::Col */}
      </div>

      {/* end::Row */}
      {/* begin::Col top product*/}
      <div className=''>
        <ListsWidget6 className='card-xl-stretch mb-5 mb-xl-8' />
      </div>
      {/* end::Col */}
    </>
  )
}

const DashboardWrapper = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle>
      <DashboardPage />
      {/* <MixedWidget4 progress='99%' color='success' title='fff' image='' date='' className=''/> */}
    </>
  )
}

export {DashboardWrapper}
